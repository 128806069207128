.pl {
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #b0cec4;
}

.pl-title {
    font-size: 40px;
    font-weight: 600;
    margin: 20px;
}

.pl-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 480px) {
    .pl {
      padding: 10px;
    }
  
    .pl-texts {
      width: 100%;
    }
  
    .pl-desc {
      display: none;
    }

    .pl-list {
      flex-direction: column;
    }
  }