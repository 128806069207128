.c {
    height: 100vh;
    position: relative;
    background-color: #f0f8ff;
}

.c-bg {
   margin-left: 100px;
   margin-top: 480px;
   width: 250px;
   height: 25%;
   background-color: #b8f5b6;
   position: absolute;
   clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
}

.c-wrapper {
    padding: 50px;
    display: flex;
}

.c-left {
    flex:1;
}

.c-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.c-title {
    font-size: 50px;
    width: 80%;
    margin: 0px 50px;
}

.c-info-item {
    display: flex;
    align-items: center;
    margin: 50px;
    font-weight: bold;
}

.c-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.c-desc {
    font-weight: bold;
    font-size: 50px;
  }

form {
    margin-top: 20px;
}

input {
    width: 50%;
    height: 50px;
    border: none;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

textarea {
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    resize: none;
  }

  button {
    border: none;
    padding: 15px;
    background-color: #59b256;
    color: white;
    font-weight: 500;
    cursor: pointer;
  }

  button:hover {
   background-color: #8ad687;
  }

  @media screen and (max-width: 480px) {
    .c-wrapper {
      flex-direction: column;
      padding: 0px 50px;
    }
  
    .c-title {
      font-size: 30px;
    }
  
    .c-info-item {
      margin: 20px 0px;
      width: 100%;
    }
  
    .c-desc {
      display: none;
    }
  
    form {
      margin-top: 0px;
      display: flex;
      flex-wrap: wrap;
    }
  
    input {
      width: 35%;
      height: 40px;
      margin: 10px;
      margin-left: 0;
    }
  
    button {
      margin-top: 10px;
    }

    .c-bg {
        display: none;
    }
  }