

.p {
    width: 30%;
    height: 40vh;
    margin: 20px 10px;
    box-shadow: rgba(49, 45, 45, 0.24) 0px 3px 8px;
    border-radius: 30px;
    overflow: hidden;
    transition: transform 1s;
}

.p:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1); 
  }

.p-img {
    height: 100%;

}

.p-desc {
    padding: 15px;
    background-color: #f5f5f5;
}

@media screen and (max-width: 480px) {
    .p {
      width: 80%;
      height: 20vh;
      margin: auto;
      margin-bottom: 40px;
    }

    .p-desc {
        max-height: 45%;
        padding: 10px;
    }
  }
