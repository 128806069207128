

.intro {
    display: flex;
    height: 100vh;
    flex-direction: row;
    background-color: #e3e3e3;
  }
  
.intro-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.intro-right {
    flex: 1;
    position: relative;
} 

.intro-left-wrapper {
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.intro-hello {
    font-size: 30px;
    font-weight: 300;
}

.intro-name {
    font-size: 60px;
}

.intro-title {
    height: 50px;
    overflow: hidden;
}

.intro-title-wrapper {
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    25% {
      transform: translateY(-50px);
    }
    50% {
      transform: translateY(-100px);
    }
    75% {
      transform: translateY(-150px);
    }
    100% {
      transform: translateY(-200px);
    }
  }

  .intro-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #59b256;
    display: flex;
    align-items: center;
  }

  .intro-img {
    width: 100%;
    height: 125%;
    object-fit: cover;
    position: absolute;
    top: -25%
  }
  
  .intro-bg {
    clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: #59b256;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media screen and (max-width: 480px) {
    .intro {
      flex-direction: column;
    }

    .intro-left-wrapper {
  
      padding: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
    }

    
  }