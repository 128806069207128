.t {
    width: 60px;
    height: 23px;
    border-radius: 20px;
    border: 1px solid rgb(131, 126, 126);
    background-color: rgb(253, 253, 253);
    position: fixed;
    top: 15px;
    left: 20px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.t-icon {
    width: 20px;
    height: 20px;
}

.t-button {
    width: 32px;
    height: 25px;
    border-radius: 50%;
    background-color: rgb(93, 96, 96);
    position: absolute;
    cursor: pointer;
    right: 0;
}