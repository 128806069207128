.a {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #c1dff4;
}

.a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.a-right {
    flex: 1;
}

.a-card {
    width: 80%;
    height: 50vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.a-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.a-card.bg {
    position: absolute;
    top: 150px;
    left: 50px;
    background-color: rgb(47, 42, 42);
}

.a-title {
    font-weight: 400;
    margin: 20px;
}

.a-about {
    font-weight: 300;
    margin: 20px;
}

@media screen and (max-width:480px) {
    .a{
      flex-direction: column;
      text-align: center;
      margin-top: 50px;
    }

    .a-left {
      width: 100%;
    }

    .a-card {
      height: 30vh;
    }

    .a-card.bg {
      display: none;
    }

    .a-right{
      padding: 20px;
    }
}